import React from 'react'
import { graphql } from 'gatsby'
import { ArrowUpRight, CalendarBlank } from 'phosphor-react'
import Confetti from 'react-confetti'
import { BasicLayout } from '../layouts/basic'
import { SEOMeta } from '../components/SEOMeta'
import { MDXContent } from '../components/MDXContent'

const ReleaseNote = ({ location, data: { mdx: releaseNote } }) => {
  const windowGlobal =
    typeof window === 'undefined'
      ? { innerWidth: 1024, innerHeight: 768 }
      : window

  return (
    <BasicLayout>
      <SEOMeta
        title={releaseNote.frontmatter.title}
        image={releaseNote.frontmatter.featuredimage?.publicURL}
        description={releaseNote.frontmatter.description}
        path={location.pathname}
      />
      {releaseNote.frontmatter.confetti && (
        <Confetti
          height={windowGlobal.innerHeight}
          width={windowGlobal.innerWidth}
          className="w-full h-full fixed"
          numberOfPieces={100}
        />
      )}
      <div className="mx-auto max-w-4xl">
        <main className="text-text-primary-light mx-auto p-6 pb-12 max-w-6xl text-base leading-relaxed">
          <header className="md:grid md:grid-flow-col md:grid-cols-4 md:mt-16">
            <h1 className="text-text-primary-light text-3xl font-extrabold tracking-tight leading-tight sm:text-4xl md:col-span-3 md:col-start-2">
              {releaseNote.frontmatter.title}
            </h1>
          </header>
          <article className="mt-3 md:grid md:grid-flow-col md:grid-cols-4 md:mt-12">
            <div className="text-text-secondary-light flex items-center self-start text-sm space-x-1 md:col-start-1 md:row-start-1 md:leading-loose">
              <CalendarBlank className="flex-shrink-0" />
              <time>{releaseNote.frontmatter.date}</time>
            </div>
            <div className="mt-6 md:col-span-3 md:col-start-2 md:mt-0">
              <div className="text-text-primary-light max-w-xl text-sm lg:max-w-2xl">
                <MDXContent>{releaseNote.body}</MDXContent>
              </div>
            </div>
            {!releaseNote.frontmatter.skipCta && (
              <footer className="md:col-start-2 md:row-start-2">
                <a
                  className="shadow-solid-sm active:bg-button-primary-active-light hover:bg-button-primary-hovered-light bg-button-primary-light flex items-center justify-between mt-8 p-4 h-14 text-white text-sm leading-tight border border-transparent rounded-lg space-x-2 sm:max-w-max"
                  href={process.env.SIGNUP_URL}
                >
                  <span className="-mt-0.5">Use it now for free</span>
                  <ArrowUpRight className="relative left-0.5" size={20} />
                </a>
              </footer>
            )}
          </article>
        </main>
      </div>
    </BasicLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug }, source: { eq: "release-notes" } }) {
      body
      frontmatter {
        title
        description
        featuredimage {
          publicURL
        }
        skipCta
        confetti
        date(formatString: "DD MMM YYYY")
      }
    }
  }
`

export default ReleaseNote
